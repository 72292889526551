function setDarkMode() {
  const htmlElement = document.querySelector("html");
  const logos = document.querySelectorAll(".mag-logo");
  htmlElement.dataset.theme = "dim";
  localStorage.setItem("theme", "dim");
  logos.forEach(logo => {
    logo.src = `/mag_logo_dark.svg`;
  });
}

function setLightMode() {
  const htmlElement = document.querySelector("html");
  const logos = document.querySelectorAll(".mag-logo");
  htmlElement.dataset.theme = "retro";
  localStorage.setItem("theme", "retro");
  logos.forEach(logo => {
    logo.src = `/mag_logo.svg`;
  });
}

export default function initTheme() {
  const themeToggle = document.getElementById("theme-toggle");
  const themeFromStore = localStorage.getItem("theme");
  if (themeFromStore) {
    if (themeFromStore === "dim") {
      setDarkMode();
    } else {
      setLightMode();
    }
  } else {
    setDarkMode();
  }


  themeToggle.addEventListener("change", function() {
    if (themeToggle.checked) {
      setDarkMode();
    } else {
      setLightMode();
    }
  });

}
