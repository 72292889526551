function onFilterLoad() {
  const filter = document.getElementById("card-filter").querySelectorAll("a");

  if (filter && filter.length > 0) {
    const setSelect = document.getElementById("filter-set");

    if (setSelect) {
      setSelect.addEventListener("change", (e) => {
        const url = e.target[e.target.selectedIndex].dataset.queryurl;
        window.location.href = url;
      });
    }
  }
  filter.forEach((item) => {
    item.addEventListener("click", (e) => {
      if (window.cardListHasUnsavedChanges === true) {
        if (
          confirm(
            "You have unsaved changes, these will be lost. Are you sure you want to leave this page?"
          )
        ) {
          window.cardListHasUnsavedChanges = false;
        } else {
          e.preventDefault();
        }
      }
    });
  });
}

export default function initCardFilter() {
  if (!document.getElementById("card-filter")) {
    console.log("No card filter found");
    return;
  }
  onFilterLoad();
}
