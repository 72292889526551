const cardTrackerLocalStorageKey = "card_collection_tracker";

function showUnsavedChangesModal() {
  const modal = document.getElementById("unsaved-changes-modal");
  if (!modal) {
    console.error("Unsaved changes modal not found");
    return;
  }
  modal.classList.add("is-active");
  const unsavedText = document.getElementById("unsaved-changes-text");
  if (unsavedText) {
    unsavedText.classList.remove("hide");
  }
  const savedText = document.getElementById("saved-changes-text");
  if (savedText) {
    savedText.innerHTML = "";
    savedText.classList.add("hide");
  }
}

function hideUnsavedChangesModal() {
  window.cardListHasUnsavedChanges = false;
  const modal = document.getElementById("unsaved-changes-modal");
  if (!modal) {
    return;
  }
  const unsavedText = document.getElementById("unsaved-changes-text");
  if (unsavedText) {
    unsavedText.classList.add("hide");
  }
  const savedText = document.getElementById("saved-changes-text");
  if (savedText) {
    savedText.classList.remove("hide");
  }

  modal.classList.add("hide");
  setTimeout(function () {
    modal.classList.remove("is-active");
    modal.classList.remove("hide");
  }, 2000);
}

/**
 *
 * @param set string
 * @param cardNumber string
 * @param cardType string
 * @param updatedCount number
 */
function saveUpdatedValueToLocalStorage(
  set,
  cardNumber,
  cardType,
  updatedCount
) {
  showUnsavedChangesModal();
  window.cardListHasUnsavedChanges = true;
  const lowerSet = set.toLowerCase();
  const currentCardCollection =
    JSON.parse(localStorage.getItem(cardTrackerLocalStorageKey)) || {};
  const currentSet = currentCardCollection[lowerSet] || {};
  const currentCard = currentSet[cardNumber] || {};

  currentCard[cardType] = updatedCount;
  currentSet[cardNumber] = currentCard;
  currentCardCollection[lowerSet] = currentSet;
  localStorage.setItem(
    cardTrackerLocalStorageKey,
    JSON.stringify(currentCardCollection)
  );
  const collectionInput = document.getElementById("user-collection");
  if (collectionInput) {
    collectionInput.value = JSON.stringify(currentCardCollection);
  }
}

function loadCardTrackerFromLocalStorage(collection) {
  if (collection) {
    return localStorage.setItem(
      cardTrackerLocalStorageKey,
      JSON.stringify(collection)
    );
  }
  console.info(" No collection from server, try to load from local storage");
  const currentCardCollection = JSON.parse(
    localStorage.getItem(cardTrackerLocalStorageKey)
  );
  if (!currentCardCollection) {
    console.info(
      "No card collection found in local storage, creating empty entry"
    );
    localStorage.setItem(cardTrackerLocalStorageKey, JSON.stringify({}));
    return;
  }

  const collectionInput = document.getElementById("user-collection");
  if (collectionInput) {
    collectionInput.value = JSON.stringify(currentCardCollection);
  }
  populateTableFromCollection(currentCardCollection);
}

function populateTableFromCollection(collection) {
  //TODO: copy the DOM and do this in memory before updating the DOM
  const sets = Object.keys(collection);
  for (const set of sets) {
    const cards = Object.keys(collection[set]);
    for (const card of cards) {
      const cardData = collection[set][card];
      const cardTypes = Object.keys(cardData);
      for (const cardType of cardTypes) {
        const count = cardData[cardType];
        const cardCountEl = document.getElementById(
          "card_count_" + set + "_" + card + "_" + cardType
        );
        if (cardCountEl) {
          cardCountEl.innerHTML = count;
        }
      }
    }
  }
}

function getMissingCardRows() {
  const missingCardRows = [];
  const cardRows = document.getElementsByClassName("card-table-row");
  for (const cardRow of cardRows) {
    const cardName = cardRow.dataset.name;
    const cardType = cardRow.dataset.type;
    const cardNumber = cardRow.dataset.number;
    const cardRarity = cardRow.dataset.rarity;
    const cardCount = Number(cardRow.dataset.total);
    if (cardType === "Base" || cardType === "Leader") {
      if (cardCount < 1) {
        missingCardRows.push(`${cardNumber} ${cardRarity} - ${cardName} (1)`);
      }
      continue;
    }

    if (cardCount < 3) {
      missingCardRows.push(
        `${cardNumber} ${cardRarity} - ${cardName} (${3 - cardCount})`
      );
    }
  }
  return missingCardRows;
}

function registerTableEvents() {
  const clickableCells = document.querySelectorAll(
    ".card-table-row .clickable"
  );
  for (let i = 0, len = clickableCells.length; i < len; i++) {
    clickableCells[i].addEventListener(
      "touchstart",
      function (e) {
        e.stopPropagation();
        e.preventDefault();
        const parentTr = this.closest(".card-table-row");
        parentTr.classList.add("touch-hover");

        parentTr.querySelector("td.card-popup").addEventListener(
          "touchstart",
          function (e) {
            e.stopPropagation();
            e.preventDefault();
            parentTr.classList.remove("touch-hover");
          },
          false
        );
      },
      false
    );
  }
}

const sorTwoPlayerSet = [
  ["010", 1],
  ["023", 1],
  ["225", 2],
  ["128", 3],
  ["129", 1],
  ["130", 2],
  ["226", 3],
  ["227", 2],
  ["228", 2],
  ["079", 1],
  ["083", 3],
  ["229", 3],
  ["230", 1],
  ["084", 3],
  ["132", 3],
  ["231", 1],
  ["232", 2],
  ["086", 1],
  ["088", 1],
  ["135", 1],
  ["089", 1],
  ["136", 3],
  ["123", 1],
  ["139", 1],
  ["126", 1],
  ["172", 3],
  ["233", 3],
  ["234", 1],
  ["092", 1],
  ["005", 1],
  ["029", 1],
  ["059", 1],
  ["236", 3],
  ["237", 3],
  ["238", 3],
  ["239", 1],
  ["044", 2],
  ["189", 3],
  ["194", 1],
  ["240", 3],
  ["241", 1],
  ["45", 1],
  ["063", 1],
  ["066", 1],
  ["046", 3],
  ["195", 1],
  ["242", 1],
  ["244", 1],
  ["196", 1],
  ["048", 1],
  ["049", 1],
  ["198", 1],
  ["069", 1],
  ["053", 3],
  ["075", 2],
  ["217", 1],
  ["218", 2],
  ["220", 2],
  ["222", 2],
  ["078", 3],
];

function AddSoRStarterSet() {
  const currentCardCollection =
    JSON.parse(localStorage.getItem(cardTrackerLocalStorageKey)) || {};
  const currentSet = currentCardCollection["sor"] || {};
  for (const card of sorTwoPlayerSet) {
    const cardData = currentSet[card[0]] || {};
    if (cardData["normal"]) {
      cardData["normal"] = cardData["normal"] + card[1];
    } else {
      cardData["normal"] = card[1];
    }
    currentSet[card[0]] = cardData;
  }
  currentCardCollection["sor"] = currentSet;
  const stringifyCollection = JSON.stringify(currentCardCollection);
  localStorage.setItem(cardTrackerLocalStorageKey, stringifyCollection);
  populateTableFromCollection(currentCardCollection);

  const collectionInput = document.getElementById("user-collection");
  if (collectionInput) {
    collectionInput.value = stringifyCollection;
  }
  showUnsavedChangesModal();
}

export default function initCardTracker() {
  window.loadCardTrackerFromLocalStorage = loadCardTrackerFromLocalStorage;
  window.saveUpdatedValueToLocalStorage = saveUpdatedValueToLocalStorage;
  window.getMissingCardRows = getMissingCardRows;
  window.addSoRStarterSet = AddSoRStarterSet;
  hideUnsavedChangesModal();
  loadCardTrackerFromLocalStorage(window.cardCollection);
  registerTableEvents();

  document.body.addEventListener("htmx:afterSwap", function (evt) {
    if (evt.target && evt.target.id === "saved-changes-text") {
      hideUnsavedChangesModal();
    }
  });
}
